<template>
  <div>
    <InternalFundingStatus v-if="status" :status="status" />
    <span v-else>{{ fallBackValue }}</span>
  </div>
</template>

<script lang="ts">
import { ICellRendererParams } from 'ag-grid-enterprise'
import { Component, Vue } from 'vue-property-decorator'

import InternalFundingStatus from '@/components/labels/InternalFundingStatus.vue'

@Component({
  name: 'internal-funding-label-renderer',
  components: {
    InternalFundingStatus,
  },
})
export default class InternalFundingLabelRenderer extends Vue {
  params: ICellRendererParams = null

  get status(): number | undefined {
    return this.params.data?.status
  }

  get fallBackValue(): string {
    return this.params.value
  }
}
</script>

<style scoped></style>
