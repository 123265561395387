<template>
  <v-wait for="fetch projects">
    <template slot="waiting">
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-center mt-8">
          <b-spinner variant="primary" label="Spinning" />
        </div>
      </div>
    </template>

    <TableSelectAlert
      :pageSize="pageSize"
      :items="projects"
      :selectedItems="selectedProjects"
      :totalItemCount="projectCount"
      :apiUrl="allProjectsAPIURL"
      @change="(value) => $emit('update:selectedProjects', value)"
      @reset="() => selectableTable.clearSelected()"
    >
      <template #items-page-selected>
        <translate key="projects-page-selected" :translate-params="{ projectCount: projects.length }">
          All %{projectCount} projects on this page are selected.
        </translate>
      </template>
      <template #select-all-items>
        <translate key="select-all-projects" :translate-params="{ projectCount: projectCount }">
          Select all {{ projectCount }} projects.
        </translate>
      </template>
      <template #all-items-selected>
        <translate key="projects-all-selected" :translate-params="{ projectCount: projectCount }">
          All %{projectCount} projects are selected.
        </translate>
      </template>
    </TableSelectAlert>

    <b-table
      ref="selectableTable"
      hover
      borderless
      striped
      outlined
      responsive
      no-local-sorting
      show-empty
      sort-icon-left
      selectable
      class="site-admin-min-table-height"
      :no-select-on-click="true"
      :empty-text="emptyText"
      :fields="availableFields"
      :items="projects"
      :busy="tableLoading"
      @sort-changed="sortingChanged"
      @row-selected="onRowSelected"
    >
      <!--<template slot="top-row" slot-scope="data">
      </template>-->
      <template v-for="col in availableFilterCols" #[`head(${col.field})`]="data">
        <span :key="col.field">
          <TableFilterDropdown
            :key="`header-${col.field}`"
            menuClass="table-filter-menu"
            :label="data.label"
            :fieldName="col.field"
            :options="getFilter(col.filter).options"
            :filterName="getFilter(col.filter).filterName"
            :selected.sync="getFilter(col.filter).selected"
            @on-apply="applyFilterAndReload"
          />
        </span>
      </template>
      <template #head(funding_status)>
        <TableOrderDropdown
          menuClass="table-filter-menu"
          :label="$gettext('Funding progress')"
          fieldName="funding_status"
          :options="[
            { text: $gettext('Funded (total)'), value: 'funding_status' },
            {
              text: $gettext('Funded in %'),
              value: 'funding_status_percentage',
            },
            { text: $gettext('Needs in total'), value: 'funding_status_goal' },
          ]"
          @order-apply="applyOrderAndReload"
        />
      </template>

      <!-- TODO: When moving this whole thing to its own module, build something to iterate through a variable and do this -->
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner variant="primary" class="align-middle" />
        </div>
      </template>

      <template #empty="scope">
        <div class="flex h-align-center v-align-center" style="minheight: 200px">
          {{ scope.emptyText }}
        </div>
      </template>

      <template #head(selected)>
        <b-form-checkbox :checked="allVisibleItemsSelected" @change="toggleCheckAll" />
      </template>

      <template #cell(selected)="data">
        <b-form-checkbox :checked="data.rowSelected !== false" @change="toggleSelect(data.index)" />
      </template>

      <template #cell(index)="data">
        {{ 1 + data.index + (currentPage - 1) * pageSize }}
      </template>

      <template #cell(title)="data">
        <div class="options-hover-center">
          <slot name="actions" :project="data.item" />
          <span class="labels" v-html="getProjectCol(data.item)" />
        </div>
      </template>

      <template #cell(status)="data">
        <ProjectStatus :project="data.item" />
      </template>

      <template #cell(review_status)="data">
        <ProjectReviewStatus :project="data.item" />
      </template>

      <template #cell(content_review_status)="data">
        <ProjectContentReviewStatus :project="data.item" />
      </template>

      <template #cell(voting_review_status)="data">
        <ProjectVotingReviewStatus :project="data.item" />
      </template>

      <template #cell(projectpromoter)="data">
        <span class="labels" v-html="getOrganizationCol(data.item)" />
      </template>

      <template #cell(financing_status)="data">
        <ProjectFinancingStatus :project="data.item" />
      </template>

      <template #cell(application_type)="data">
        <span class="labels" v-html="getApplicationTypeCol(data.item)" />
      </template>

      <template #cell(internal_status)="data">
        <span class="labels" v-html="getInternalStatusCol(data.item)" />
      </template>

      <template #cell(private_donations)="data">
        <span v-html="getPrivateDonationsCol(data.item)" />
      </template>

      <template #cell(funding_account)="data">
        <ProjectFundingAccountLabel :project="data.item" />
      </template>

      <template #cell(organization_status)="data">
        <OrganizationReviewStatus :organization="data.item.organization" :showNOELabel="true" />
      </template>

      <template #cell(statutory_purpose_list)="data">
        <!-- TODO improve this somehow -->
        <div class="td-statutory_purpose_list" :title="data.item.organization.statutory_purpose_list">
          {{ data.item.organization.statutory_purpose_list || '-' }}
        </div>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      aria-controls="project-list"
      :prev-text="'‹ ' + $gettext('Prev')"
      :next-text="$gettext('Next') + ' ›'"
      :total-rows="projectCount"
      :per-page="pageSize"
      first-number
      last-number
    />
  </v-wait>
</template>

<script lang="ts">
import axios, { AxiosResponse } from 'axios'
import { Component, Mixins } from 'vue-property-decorator'

import OrganizationReviewStatus from '@/components/labels/OrganizationReviewStatus.vue'
import ProjectContentReviewStatus from '@/components/labels/ProjectContentReviewStatus.vue'
import ProjectFinancingStatus from '@/components/labels/ProjectFinancingStatus.vue'
import ProjectFundingAccountLabel from '@/components/labels/ProjectFundingAccountLabel.vue'
import ProjectReviewStatus from '@/components/labels/ProjectReviewStatus.vue'
import ProjectStatus from '@/components/labels/ProjectStatus.vue'
import ProjectVotingReviewStatus from '@/components/labels/ProjectVotingReviewStatus.vue'
import TableFilterDropdown from '@/components/TableFilterDropdown.vue'
import TableOrderDropdown from '@/components/utils/TableOrderDropdown.vue'
import TableSelectAlert from '@/components/utils/TableSelectAlert.vue'
import ProjectTableMixin from '@/mixins/ProjectTableMixin'
import { IApplicationType } from '@/types/applicationTypes'
import { IInternalStatus, TGenericObject } from '@/types/base'
import { ICompany } from '@/types/companies'
import { IFilters } from '@/types/filters'
import { IBudget, IIndividualBankAccount } from '@/types/finances'
import { IMinimalProject, IProject } from '@/types/projects'
import {
  contentStatusOptions,
  organizationTypeOptions,
  progressStatusOptions,
  reviewStatusOptions,
  statutoryPurposeOptions,
} from '@/utils/filterOptions'
import { addContextToUrl, API_URLS } from '@/utils/helpers'

@Component({
  components: {
    TableFilterDropdown,
    OrganizationReviewStatus,
    ProjectStatus,
    ProjectReviewStatus,
    ProjectContentReviewStatus,
    ProjectVotingReviewStatus,
    ProjectFinancingStatus,
    ProjectFundingAccountLabel,
    TableOrderDropdown,
    TableSelectAlert,
  },
  name: 'project-table',
})
export default class ProjectTable extends Mixins(ProjectTableMixin) {
  // Table field definitions. Check https://bootstrap-vue.js.org/docs/components/table/ for more
  selectProjectNames: string[] = []
  internalStatuses: IInternalStatus[] = []
  filterCols: { field: string; filter: string }[] = [
    { field: 'status', filter: 'projectStatusFilter' },
    { field: 'application_type', filter: 'applicationTypeFilter' },
    { field: 'review_status', filter: 'reviewStatusFilter' },
    { field: 'financing_status', filter: 'financingStatusFilter' },
    { field: 'voting_review_status', filter: 'votingReviewStatusFilter' },
    { field: 'content_review_status', filter: 'contentReviewStatusFilter' },
    { field: 'internal_status', filter: 'internalStatusFilter' },
    { field: 'organization_status', filter: 'organizationStatusFilter' },
    { field: 'funding_account', filter: 'fundingAccountFilter' },
    { field: 'supported_by_companies', filter: 'supportedByCompaniesFilter' },
    {
      field: 'supported_by_donation_limits',
      filter: 'supportedByBudgetsFilter',
    },
    { field: 'category_title', filter: 'categoryFilter' },
    { field: 'organization_type', filter: 'organizationTypeFilter' },
    { field: 'statutory_purpose_list', filter: 'statutoryPurposeListFilter' },
    { field: 'projectpromoter', filter: 'projectPromoterFilter' },
  ]

  filters: IFilters = {
    // Define filter options. Those that we see in the dropdowns
    projectStatusFilter: {
      filterName: 'status_named',
      selected: [],
      options: progressStatusOptions(this.$language.current),
    },
    organizationStatusFilter: {
      filterName: 'organization_status_named',
      selected: [],
      options: {
        public: this.$gettext('Public'),
        rejected: this.$gettext('Rejected'),
        accepted: this.$gettext('Accepted'),
        needs_review: this.$gettext('Needs review'),
        noe_expired: this.$gettext('Proof expired'),
      },
    },
    applicationTypeFilter: {
      filterName: 'application_type',
      selected: [],
      options: {},
    },
    reviewStatusFilter: {
      filterName: 'review_status_named',
      selected: [],
      options: reviewStatusOptions(this.$language.current),
    },
    financingStatusFilter: {
      filterName: 'financing_status',
      selected: [],
      options: {
        fully_funded: this.$gettext('Fully funded'),
        expired: this.$gettext('Expired'),
        can_receive_fundings: this.$gettext('In financing'),
        is_banned: this.$gettext('Banned'),
      },
    },
    contentReviewStatusFilter: {
      filterName: 'content_review_status_named',
      selected: [],
      options: contentStatusOptions(this.$language.current),
    },
    votingReviewStatusFilter: {
      filterName: 'voting_review_status_named',
      selected: [],
      options: {
        needs_review: this.$gettext('Needs review'),
        public: this.$gettext('Public'),
        rejected: this.$gettext('Rejected'),
      },
    },
    fundingAccountFilter: {
      filterName: 'funding_account_named',
      selected: [],
      options: {},
    },
    internalStatusFilter: {
      filterName: 'internal_status__title',
      selected: [],
    },
    supportedByCompaniesFilter: {
      filterName: 'supported_by_companies',
      selected: [],
      options: {},
    },
    supportedByBudgetsFilter: {
      filterName: 'supported_by_donation_limits',
      selected: [],
      options: {},
    },
    categoryFilter: {
      filterName: 'category_slug',
      selected: [],
      options: {},
    },
    organizationTypeFilter: {
      filterName: 'organization_type_named',
      selected: [],
      options: organizationTypeOptions(this.$language.current),
    },
    statutoryPurposeListFilter: {
      filterName: 'statutory_purpose_list',
      selected: [],
      options: statutoryPurposeOptions(this.$language.current),
    },
    projectPromoterFilter: {
      filterName: 'organization_slug',
      selected: [],
      options: {},
    },
  }

  getInternalStatusCol(project: IProject): string {
    return project.internal_status ? project.internal_status.label : '-'
  }

  getApplicationTypeCol(project: IProject): string {
    return `<span class="badge badge-info label-fundingtype-${
      project.application_type ? project.application_type.slug : 'no-application_type'
    }">${project.application_type ? project.application_type.title : '-'}</span>`
  }

  getPrivateDonationsCol(project: IProject): string {
    if (project.private_donation_amount.in_cents > 0) {
      return `<a
        href="${API_URLS.PRIVATE_DONATIONS.EXPORT('project_slug=' + project.slug + '&status_named=verified')}" 
        target="_blank">
          ${this.toCurrency(project.private_donation_amount.in_currency)}
        </a>`
    }
    return this.toCurrency(project.private_donation_amount.in_currency)
  }

  async loadAllFilteredProjectNames(): Promise<AxiosResponse<IMinimalProject[]>> {
    return axios.get(addContextToUrl(API_URLS.PROJECTS.MINIMAL_LIST, this.urlContext))
  }

  async loadInitialProjects(): Promise<void> {
    this.$wait.start('fetch projects')
    await Promise.all([this.loadProjects(), this.loadInternalStatuses()])
    // If we have internal statuses, create dict of them, add them to filter options and add column to table
    if (this.internalStatuses.length > 0) {
      this.filters.internalStatusFilter.options = Object.fromEntries(
        this.internalStatuses.map((item) => [item.title, item.title])
      )
    }
    // Filter name for content review status is different than that of provided **FIXED
    if (this.projects.length > 0 && Object.prototype.hasOwnProperty.call(this.projects[0], 'content_review_status')) {
      this.filters.organizationStatusFilter.options = Object.assign(this.filters.organizationStatusFilter.options, {
        content_accepted: this.$gettext('Content accepted'),
        content_rejected: this.$gettext('Content rejected'),
        nonprofit: this.$gettext('nonprofit'),
        not_nonprofit: this.$gettext('Not nonprofit'),
      })
    }
    this.$wait.end('fetch projects')
  }

  async loadInternalStatuses(): Promise<void> {
    const url = `${API_URLS.PROJECTS.INTERNAL_STATUS}?page_size=100`
    return axios.get(url).then((response) => {
      this.internalStatuses = response.data.results
    })
  }

  async loadBankAccounts(): Promise<void> {
    axios.get(API_URLS.INDIVIDUAL_BANK_ACCOUNTS.LIST).then((response) => {
      const individualBankAccounts = response.data.results as IIndividualBankAccount[]
      for (const bankAccount of individualBankAccounts) {
        this.filters.fundingAccountFilter.options[bankAccount.slug] = bankAccount.title
      }
    })
  }

  async loadCategories(): Promise<void> {
    axios.get(API_URLS.EXPLORE.PROJECTS.CATEGORIES).then((response) => {
      for (const category of response.data) {
        this.filters.categoryFilter.options[category.slug] = category.title
      }
    })
  }

  async loadSupportedByOptions(): Promise<void> {
    axios.get<ICompany[]>(API_URLS.COMPANIES.DONATING_COMPANIES).then((response) => {
      const companies = response.data
      for (const company of companies) {
        this.filters.supportedByCompaniesFilter.options[company.slug] = company.title
      }
    })
    axios.get<IBudget[]>(API_URLS.DONATION_LIMITS.COMPANIES.MINIMAL_LIST).then((response) => {
      const budgets = response.data
      for (const budget of budgets) {
        this.filters.supportedByBudgetsFilter.options[budget.slug] = budget.title
      }
    })
  }

  async loadApplicationTypes(): Promise<void> {
    axios.get<IApplicationType[]>(API_URLS.APPLICATION_TYPES.MINIMAL_LIST).then((response) => {
      for (const applicationType of response.data) {
        this.filters.applicationTypeFilter.options[applicationType.slug] = applicationType.title
      }
    })
  }

  async loadOrganizationsOptions(): Promise<void> {
    const contextCopy = { ...this.urlContext }
    delete contextCopy.sortBy
    axios.get(addContextToUrl(API_URLS.PROJECTS.MINIMAL_ORGANIZATION_LIST, contextCopy)).then((response) => {
      // Reseting the options object to an empty object because for some reason it wasn't reactive to the changes made.
      this.filters.projectPromoterFilter.options = {}
      for (const organization of response.data as TGenericObject[]) {
        // Generic object, because of different field naming bc of the server side annotate
        this.filters.projectPromoterFilter.options[organization.organization_slug] = organization.organization_title
      }
    })
  }

  async handleOrganizationFilterUpdate(): Promise<void> {
    this.loadOrganizationsOptions()
  }

  async created(): Promise<void> {
    this.loadInitialProjects()
    this.loadBankAccounts()
    this.loadSupportedByOptions()
    this.loadApplicationTypes()
    this.loadCategories()
    this.loadOrganizationsOptions()
    this.$on('total-count-update', this.handleOrganizationFilterUpdate) // emitted on loadProjects
  }
}
</script>
